<template>
<div class="table-content">
        <div class="table-options">
            <div class="delete" v-if="selected.length > 0">
                <vs-icon icon="delete"></vs-icon>
            </div>
        </div>
        <div class="table-wrapper with-border">
            <vs-table :data="bookings">
                <template slot="thead">
                    <vs-th sort-key="Reference">Referencia</vs-th>
                    <vs-th sort-key="AgentCode">Agencia</vs-th>
                    <vs-th sort-key="BookingName">Nombre</vs-th>
                    <vs-th sort-key="SellDate">Sell Date</vs-th>
                    <vs-th sort-key="TravelDate">Travel Date</vs-th>
                    <vs-th sort-key="ConsultantCode">Agente</vs-th>
                    <vs-th sort-key="BookingStatus">Estado</vs-th>
                    <vs-th sort-key="PAX">Pax </vs-th>
                    <vs-th sort-key="COST">Cost</vs-th>
                    <vs-th sort-key="RETAIL"> Retail  </vs-th>
                    <vs-th sort-key="PORC_RETAIL"> 1% Retail</vs-th>
                    <vs-th sort-key="RETAILREAL">Retail Real</vs-th>
                    <vs-th sort-key="MARGIN">Margin </vs-th>
                    <vs-th class="center"></vs-th>
                </template>
                <template slot-scope="{ data }">
                    <template :data="tr"  v-for="(tr, indextr) in data">
                        <vs-tr :key="indextr" :class="{'active': opened.includes(data[indextr].id)}">
                            <vs-td :data="data[indextr].Reference" class="center"> {{ data[indextr].Reference }} </vs-td>
                            <vs-td :data="data[indextr].AgentCode"> {{ data[indextr].AgentCode }} </vs-td>
                            <vs-td :data="data[indextr].BookingName"> {{ data[indextr].BookingName }} </vs-td>
                            <vs-td :data="data[indextr].SellDate"> {{ data[indextr].SellDate | moment('timezone', 'Etc/GMT', 'll')}} </vs-td>
                            <vs-td :data="data[indextr].TravelDate"> {{ data[indextr].TravelDate | moment('timezone', 'Etc/GMT', 'll')}} </vs-td>
                            <vs-td :data="data[indextr].ConsultantCode"> {{ data[indextr].ConsultantCode }} </vs-td>
                            <vs-td :data="data[indextr].BookingStatus"> {{ data[indextr].BookingStatus }} </vs-td>
                            <vs-td :data="data[indextr].PAX"> {{ data[indextr].PAX }} </vs-td>
                            <vs-td :data="data[indextr].COST"> {{ $n(data[indextr].COST, 'currency') }} </vs-td>
                            <vs-td :data="data[indextr].RETAIL"> {{ $n(data[indextr].RETAIL, 'currency') }} </vs-td>
                            <vs-td :data="data[indextr].PORC_RETAIL"> {{ $n(data[indextr].PORC_RETAIL, 'currency') }} </vs-td>
                            <vs-td :data="data[indextr].RETAILREAL"> {{ $n(data[indextr].RETAILREAL, 'currency') }} </vs-td>
                            <vs-td :data="data[indextr].MARGIN"> {{ $n(data[indextr].MARGIN, 'percent') }}</vs-td>
                            <vs-td class="options">
                                <vs-icon icon="expand_more" @click="toggleRow(indextr)"></vs-icon>
                            </vs-td> 
                        </vs-tr>
                        <vs-tr :key="`sub-${indextr}`" v-if="opened.includes(indextr)">
                            <vs-td colspan="13" class="noLineClamp">
                                <div class="bookingInfo">
                                    <div class="travel-info">
                                        <div class="item">
                                            <span class="subtitle">Nombre</span>
                                            <p>{{  data[indextr].BookingName }}</p>
                                        </div>
                                        <div class="item">
                                            <span class="subtitle">Fecha Venta</span>
                                            <p>{{  data[indextr].SellDate | moment('timezone', 'Etc/GMT', 'lll') }}</p>
                                        </div>
                                        <div class="item">
                                            <span class="subtitle">Fecha Viaje</span>
                                            <p>{{  data[indextr].TravelDate | moment('timezone', 'Etc/GMT', 'lll') }}</p>
                                        </div>
                                        <div class="item">
                                            <span class="subtitle">Cantidad de viajeros</span>
                                            <p>{{  data[indextr].PAX }}</p>
                                        </div>
                                    </div>
                                    <div class="service">
                                        <div class="notes">
                                            <div class="subtitle">Reservación - Estado</div>
                                            <p>{{ data[indextr].BookingStatus }}</p>
                                        </div>
                                        <div class="notes">
                                            <div class="subtitle">Agencia</div>
                                            <p>{{ data[indextr].AgentName }}</p>
                                            <span class="voucher">Código: {{ data[indextr].AgentCode }}</span>
                                        </div>
                                        <div class="notes">
                                            <div class="subtitle">Agente</div>
                                            <p>{{ data[indextr].ConsultantName }}</p>
                                            <span class="voucher">Código: {{ data[indextr].ConsultantCode }}</span>
                                        </div>
                                    </div>
                                </div>
                            </vs-td>
                        </vs-tr>
                    </template>
                </template>
                <tr>
                    <td colspan=""></td>
                </tr>
            </vs-table>
        </div>
    </div>
</template>
<script>
export default {
    name: "TableBookingList",
    props: {
        items: { type: Array, default: () => [] },
    },
    data () {
        return {
            opened: [],
            selected:[],
            bookings: this.items,
        }
    },
    watch: {
        items(val){
            this.bookings = val
        }
    },
    methods: {
        toggleRow(id){
            const index   = this.opened.indexOf(id)
            this.opened = []
            if (index > -1) {
                this.opened.splice(index, 1)
                this.activeRow = -1
            } else {
                this.opened.push(id)
                this.activeRow = id
            }
        },
    }
}
</script>